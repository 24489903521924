import React from "react";
import Button from "../Button";
import LoadingTimeout from "./LoadingTimeout";

const Options10 = props => {
    const { stage, setStage, selectedOptions, setSelectedOptions } = props
    return (
        <>
            <p className="headline3">Se você é fonoaudiólogo(a) ou possui um centro auditivo e busca comprar aparelhos auditivos em atacado, você está no lugar certo. A Euro é referência há 18 anos, possuímos baixa burocracia, cadastro fácil e envio no mesmo dia. Nosso atendimento é humanizado e o suporte técnico é extremamente eficiente. E tudo isso aliado a produtos de altíssima qualidade por um valor justo e diversos serviços agregados.</p>

            <div className="answer-field">
                <LoadingTimeout timeout={8000}>
                    <Button nextStage={1} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} >Quero saber mais</Button>
                    <Button nextStage={21} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} >Busco aparelho para uso pessoal</Button>
                </LoadingTimeout>
            </div>

        </>
    )
}

export default Options10