import React from "react";
import Button from "../Button";
import LoadingTimeout from "./LoadingTimeout";

const Options12 = props => {
    const { stage, setStage, selectedOptions, setSelectedOptions, voltarPaginaAnterior } = props
    return (
        <>
            <p className="headline3">Qual a principal vantagem que você procura na Euro Audiologia?</p>
            <div className="answer-field">
            <LoadingTimeout timeout={2000}>
                <Button nextStage={3} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} value={'baixa_burocracia'} >Baixa burocracia para cadastro e compra de produtos</Button>
                <Button nextStage={3} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} value={'treinamento'}>Treinamento e suporte técnico eficiente</Button>
                <Button nextStage={3} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} value={'envio'}>Envio rápido de produtos</Button>
                <Button nextStage={3} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} value={'diversas_vantagens'}>Mais de uma vantagem citada</Button>
                {stage !== 0 && <button className="voltar" onClick={voltarPaginaAnterior}>Voltar para a página anterior</button>}
            </LoadingTimeout>
            </div>
        </>
    )
}

export default Options12