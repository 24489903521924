import React from "react";
import Button from "../Button";
import LoadingTimeout from './LoadingTimeout'

const Options0 = props => {
    const { stage, setStage, selectedOptions, setSelectedOptions } = props
    return (
        <>
            <p className="headline3">Quando o assunto é perda auditiva, a Euro é referência há 18 anos em Porto Alegre. Oferecemos aparelhos auditivos de altíssima qualidade por um valor justo.</p>
            <div className="answer-field">
                <LoadingTimeout timeout={2000}>
                    <Button nextStage={1} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} value={'para_mim'} >Quero saber mais</Button>
                </LoadingTimeout>
            </div>
        </>
    )
}

export default Options0