import React from "react";

const Options20 = props => {
    const { stage, voltarPaginaAnterior } = props

    const openWhatsAppChat = () => {
        const phoneNumber = '555186443398';
        const message = `Olá Euro Audiologia!\nGostaria de esclarecer algumas dúvidas.`
        const encodedMessage = encodeURIComponent(message);
        const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(url, '_blank');
      }

    return (
        <>
            <p className="headline3">Com certeza! Será um prazer esclarecer quaisquer dúvidas. Clique no botão abaixo e fale diretamente conosco pelo Whatsapp.
            </p>
            <div className="answer-field">
                <button className="more-info" onClick={openWhatsAppChat}>Falar pelo Whatsapp</button>
                {stage !== 0 && <button className="voltar" onClick={voltarPaginaAnterior}>Voltar para a página anterior</button>}
            </div>
        </>
    )
}

export default Options20