import React from "react";
import Button from "../Button";
import LoadingTimeout from './LoadingTimeout'


const Options2 = props => {
    const { stage, setStage, selectedOptions, setSelectedOptions, voltarPaginaAnterior } = props
    const headlineMap = {
        'para_mim': 'Qual o principal sintoma que você está observando?',
        'para_meu_pai': 'Qual o principal sintoma que seu pai está observando?',
        'para_minha_mãe': 'Qual o principal sintoma que sua mãe está observando?',
        'para_um_conhecido': 'Qual o principal sintoma que seu conhecido está observando?',
    }
    return (
        <>
            <p className="headline3">{headlineMap[selectedOptions[1]]}</p>
            <div className="answer-field">
                <LoadingTimeout timeout={2000}>
                    <Button nextStage={3} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} value={'dificuldade_conversacao'} >Dificuldade de conversação</Button>
                    <Button nextStage={3} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} value={'dificuldade_ouvir_tv'}>Dificuldade de ouvir a televisão</Button>
                    <Button nextStage={3} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} value={'zumbido'}>Zumbido no ouvido</Button>
                    <Button nextStage={3} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} value={'diversos_sintomas_citados'}>Diversos sintomas citados</Button>
                    {stage !== 0 && <button className="voltar" onClick={voltarPaginaAnterior}>Voltar para a página anterior</button>}
                </LoadingTimeout>
            </div>
        </>
    )
}

export default Options2