import React from "react";
import { useState, useEffect } from "react";

const LoadingTimeout = props => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, props.timeout)
    }, [])

    return (
        <>
        {loading ? <div style={{width: '100%', height: '70px', display: 'flex', justifyContent: 'center'}}><span className="loader"></span></div> : props.children}
        </>
    )
}

export default LoadingTimeout