import React from "react";
import Button from "../Button";
import LoadingTimeout from "./LoadingTimeout";

const Options11 = props => {
    const { stage, setStage, selectedOptions, setSelectedOptions, voltarPaginaAnterior } = props
    return (
        <>
            <p className="headline3">Para qual finalidade você busca adquirir aparelhos auditivos em atacado?</p>
            <div className="answer-field">
            <LoadingTimeout timeout={2000}>
                <Button nextStage={2} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} value={'centro_auditivo'} >Tenho um centro auditivo e busco mais opções de produtos</Button>
                <Button nextStage={2} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} value={'insatisfeito_fornecedor'}>Estou insatisfeito com o meu fornecedor atual</Button>
                <Button nextStage={2} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} value={'abrir_centro_auditivo'}>Quero abrir um centro auditivo e estou conhecendo fornecedores</Button>
                <Button nextStage={2} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} value={'sou_fonoaudiologo'}>Sou fonoaudiólogo(a) e atuo em outras áreas e estou interessado(a) em começar a trabalhar com aparelhos</Button>
                {stage !== 0 && <button className="voltar" onClick={voltarPaginaAnterior}>Voltar para a página anterior</button>}
                </LoadingTimeout>
            </div>
        </>
    )
}

export default Options11