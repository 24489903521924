import React from "react";

const Button = props => {
    const { stage, setStage, selectedOptions, setSelectedOptions, children, value, nextStage, className } = props

    return (
        <button className={className} onClick={() => {
            if (value) {
                let newOptions = { ...selectedOptions }
                newOptions[stage] = value
                setSelectedOptions({ ...newOptions })
            }
            setStage(nextStage)
            
            
        }
        }
        >{children}</button>
    )
}

export default Button