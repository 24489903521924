import React from "react";

const Options21 = props => {
    const { stage, voltarPaginaAnterior } = props


    return (
        <>
            <p className="headline3">A Euro só vende aparelhos auditivos para uso pessoal em Porto Alegre, no Rio Grande do Sul. Caso você more em outra cidade, infelizmente não será possível prosseguir. Obrigado pelo interesse!
            </p>
            
        </>
    )
}

export default Options21