import React from "react";
import Button from "../Button";
import LoadingTimeout from './LoadingTimeout'

const Options3 = props => {
    const { stage, setStage, selectedOptions, setSelectedOptions, voltarPaginaAnterior } = props
    const headlineMap = {
        'para_mim': ['a dificuldade que você', ' Você', 'você'],
        'para_meu_pai': ['a dificuldade que seu pai', ' Seu pai', 'ele'],
        'para_minha_mãe': ['a dificuldade que sua mãe', ' Sua mãe', 'ela'],
        'para_um_conhecido': ['a dificuldade que seu conhecido', ' O paciente', 'o paciente'],
        'dificuldade_conversacao': 'dificuldades de conversação',
        'dificuldade_ouvir_tv': 'dificuldades de ouvir televisão',
        'zumbido': 'problemas de zumbido no ouvido',
        'diversos_sintomas_citados': 'graus elevados de perda auditiva'
    }
    return (
        <>
            <p className="headline3">É muito provável que um aparelho auditivo resolva {headlineMap[selectedOptions[1]][0]} está enfrentando.
                O próximo passo é agendar uma consulta, 100% gratuita, com uma de nossas fonoaudiólogas especializadas e identificar o aparelho ideal para sanar {headlineMap[selectedOptions[2]]}.
                {headlineMap[selectedOptions[1]][1]} poderá testar o aparelho por 7 dias. Caso {headlineMap[selectedOptions[1]][2]} não se adapte e queira devolver, nenhum valor será cobrado.
            </p>
            <div className="answer-field">
                <LoadingTimeout timeout={10000}>
                    <Button nextStage={4} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions}>Quero agendar uma consulta grátis</Button>
                    {/* <Button nextStage={20} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions}>Gostaria de mais informações</Button> */}
                    {stage !== 0 && <button className="voltar" onClick={voltarPaginaAnterior}>Voltar para a página anterior</button>}
                </LoadingTimeout>
            </div>
        </>
    )
}

export default Options3