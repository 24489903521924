import React from "react";
import Button from "../Button";
import { Link  } from "react-router-dom";

const Start = props => {
    const { stage, setStage, selectedOptions, setSelectedOptions } = props

    return (
        <>
            <p className="headline3">Quando o assuto é perda auditiva, a Euro é referência em Porto Alegre e em todo território nacional. Oferecemos produtos de altíssima qualidade por um valor justo.</p>
            <p className="headline3" style={{ paddingTop: '0px' }}>O que você procura?</p>
            <div className="answer-field">
                <Link to="/varejo"><Button nextStage={1} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} value={'varejo'} >Procuro aparelho auditivo para mim ou para um familiar/conhecido</Button></Link>
                <Link to="/atacado"><Button nextStage={1} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} value={'atacado'} >Procuro aparelhos auditivos para revender na minha empresa</Button></Link>

            </div>
        </>
    )
}

export default Start