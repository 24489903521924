import React from "react";
import Options0 from "./Options0";
import Options1 from "./Options1";
import Options2 from "./Options2";
import Options3 from "./Options3";
import Options4Form from "./Options4Form";
import Options20 from "./Options20";

const FormVarejo = props => {
    const { stage, setStage, selectedOptions, setSelectedOptions } = props


    const voltarPaginaAnterior = () => {
        if (stage === 20) setStage(3)
        // else if (stage == 1)  navigate("/")
        else setStage(stage - 1)
    }
    return (
        <>
            {stage === 0 && <Options0 stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} voltarPaginaAnterior={voltarPaginaAnterior}/>}
            {stage === 1 && <Options1 stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} voltarPaginaAnterior={voltarPaginaAnterior}/>}
            {stage === 2 && <Options2 stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} voltarPaginaAnterior={voltarPaginaAnterior}/>}
            {stage === 3 && <Options3 stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} voltarPaginaAnterior={voltarPaginaAnterior}/>}
            {stage === 4 && <Options4Form stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} voltarPaginaAnterior={voltarPaginaAnterior}/>}
            {stage === 20 && <Options20 stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} voltarPaginaAnterior={voltarPaginaAnterior}/>}
        </>
    )
}

export default FormVarejo