import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import FormVarejo from './Forms/FormVarejo';
import FormAtacado from './Forms/FormAtacado';
import headerImage from './assets/header-image.png';
import { useState } from 'react';
import Start from './Forms/Start';
import logo from './assets/logo_euro_globo.png';


function App() {

  const [stage, setStage] = useState(0)
  const [selectedOptions, setSelectedOptions] = useState({
    'name': '',
    'company': '',
    'email': '',
    'phone': ''
  })

  return (
    <BrowserRouter>
      <div className="App">
        <header className="form-header">
          <img className="header-bg-image" src={headerImage} alt="Aparelhos Auditivos" />
        </header>
        <div className='form-container' style={{ marginTop: '-10px' }}>
          <div className="header-logo"><img src={logo} alt="" /></div>
          <p className="headline1">Euro Audiologia</p>
          <p className="headline2">Aparelhos Auditivos</p>
          <p className="">⭐⭐⭐⭐⭐</p>
          <p className="tiny-letter">Avaliação de 87 clientes</p>

          <Routes>
            <Route path="/" element={<Start stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} />} />
            <Route path="/varejo" element={<FormVarejo stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} />} />
            <Route path="/atacado" element={<FormAtacado stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} />} />
          </Routes>

        </div>

      </div>
    </BrowserRouter>
  );
}

export default App;
