import React from "react";
import Button from "../Button";
import { useState } from "react";

const Options4Form = (props) => {
    const { stage, selectedOptions, setSelectedOptions, voltarPaginaAnterior } = props
    const SERVER_ENDPOINT = 'https://unlimited-server.onrender.com/api/leads/add'
    // const SERVER_ENDPOINT = 'http://127.0.0.1:5000/api/leads/add'
    const [isLoading, setLoading] = useState(false)
    const headlineMap = {
        para_mim: "você",
        para_meu_pai:
            "o seu pai. Caso você tenha disponibilidade de acompanhá-lo, facilitaria muito a comunicação e o deslocamento até nossa clínica",
        para_minha_mãe:
            "a sua mãe. Caso você tenha disponibilidade de acompanhá-la, facilitaria muito a comunicação e o deslocamento até nossa clínica",
        para_um_conhecido: "seu conhecido",
    };
    const messageMap = {
        para_mim: "para mim",
        para_meu_pai:
            "para o meu pai",
        para_minha_mãe:
            "para a minha mãe",
        para_um_conhecido: "para um conhecido",
        'dificuldade_conversacao': 'dificuldade de conversação.',
        'dificuldade_ouvir_tv': 'dificuldade de ouvir televisão.',
        'zumbido': 'zumbido no ouvido.',
        'diversos_sintomas_citados': 'mais de um sintoma citado.'
    };


    const saveFormToDatabase = async (e) => {
        if (!isFormValid()) {
            window.alert('Informe seus dados no formulário')
            return
        }
        setLoading(true)
        let fbc = getFBC()
        let fbp = getFBP()
        let gclid = getGCLID()
        let source = gclid ? 'google' : fbc ? 'meta' : null
        let data = {
            source: source,
            name: selectedOptions['name'],
            phone: selectedOptions['phone'],
            domain_url: window.location.host,
            page_url: window.location.href,
            fbc: fbc,
            fbp: fbp,
            gclid: gclid,
            aditional_data: {
                para_quem: selectedOptions[1],
                vantagem: selectedOptions[2]
            }
        }
        try {
            const response = await fetch(SERVER_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }

            const responseData = await response.json();
            console.log('Success:', responseData);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            openWhatsAppChat();
            setLoading(false)
        }
    }

    const openWhatsAppChat = () => {
        const phoneNumber = '555133378211';
        const message = `Olá Euro Audiologia!\nMe chamo ${selectedOptions["name"]} e estou procurando aparelho auditivo ${messageMap[selectedOptions[1]]}.\nO principal sintoma observado foi: ${messageMap[selectedOptions[2]]}`
        const encodedMessage = encodeURIComponent(message);
        const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(url, '_blank');
    }

    // Function to format phone number
    const formatPhoneNumber = (input) => {
        let phoneNumber = input.replace(/\D/g, '');
        if (phoneNumber.length === 1) {
            // Format (x
            phoneNumber = "(" + phoneNumber.substring(0, 2);
        } else if (phoneNumber.length >= 2 && phoneNumber.length <= 6) {
            // Format (xx) xxxxx
            phoneNumber = "(" + phoneNumber.substring(0, 2) + ") " +
                phoneNumber.substring(2, 7)
        } else if (phoneNumber.length >= 7) {
            // Format (xx) xxxxx-9999
            phoneNumber = "(" + phoneNumber.substring(0, 2) + ") " +
                phoneNumber.substring(2, 7) + "-" +
                phoneNumber.substring(7, 11);
        }
        return phoneNumber;
    };

    const isFormValid = () => {
        let phonePattern = /^\(\d{2}\) \d{5}-\d{4}$/;

        let nameOk = selectedOptions['name'].length > 0
        let phoneOk = phonePattern.test(selectedOptions['phone'])
        return nameOk && phoneOk
    }

    const getFBP = () => {
        const fbpCookie = document.cookie
            .split('; ')
            .find(cookie => cookie.startsWith('_fbp='));
        return fbpCookie ? fbpCookie.split('=')[1] : null;
    };

    const getFBC = () => {
        const fbcCookie = document.cookie
            .split('; ')
            .find(row => row.startsWith('_fbc='));
        if (fbcCookie) {
            return fbcCookie.split('=')[1];
        }
        const urlParams = new URLSearchParams(window.location.search);
        const fbclid = urlParams.get('fbclid');
        if (fbclid) {
            return `fb.1.${Date.now()}.${fbclid}`;
        }
        return null;
    }

    const getGCLID = () => {
        if (typeof window === "undefined" || !window.location) {
            return null;
        }
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        const gclid = params.get('gclid');
        return gclid;
    }





    return (
        <>
            <p className="headline3">
                Ótimo! Será um prazer receber {headlineMap[selectedOptions[1]]}.
                Estamos localizados na Av. Benjamin Constant, 904. <br/><br/>
                Por gentileza, informe seus dados de contato.
            </p>
            <div className="answer-field">
                <input
                    placeholder="Seu nome"
                    onChange={(e) => {
                        let newOptions = { ...selectedOptions };
                        newOptions["name"] = e.target.value;
                        setSelectedOptions({ ...newOptions });
                    }}
                    value={selectedOptions["name"]}
                />

                <input
                    placeholder="Digite seu telefone com DDD"
                    id="phone"
                    onChange={(e) => {
                        let formattedPhoneNumber = formatPhoneNumber(e.target.value);
                        let newOptions = { ...selectedOptions };
                        newOptions["phone"] = formattedPhoneNumber;
                        setSelectedOptions({ ...newOptions });
                    }}
                    value={selectedOptions["phone"]}
                />


                {isLoading ? <span className="loader"></span> : <button onClick={saveFormToDatabase} style={{ marginTop: '20px' }} className={isFormValid() ? 'form-varejo-ok' : 'waiting-form'}>{isFormValid() ? 'Enviar' : 'Aguardando preenchimento...'}</button>}
                {/* {stage !== 0 && <button className="voltar" onClick={voltarPaginaAnterior}>Voltar para a página anterior</button>} */}
            </div>
        </>
    );
};

export default Options4Form;
