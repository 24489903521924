import React from "react";
import Button from "../Button";
import LoadingTimeout from './LoadingTimeout'

const Options1 = props => {
    const { stage, setStage, selectedOptions, setSelectedOptions, voltarPaginaAnterior } = props
    return (
        <>
            <p className="headline3">Para quem você está procurando um aparelho auditivo?</p>
            <div className="answer-field">
            <LoadingTimeout timeout={2000}>
                <Button nextStage={2} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} value={'para_mim'} >Para mim</Button>
                <Button nextStage={2} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} value={'para_meu_pai'}>Para meu pai</Button>
                <Button nextStage={2} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} value={'para_minha_mãe'}>Para minha mãe</Button>
                <Button nextStage={2} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} value={'para_um_conhecido'}>Para um conhecido</Button>
                {stage !== 0 && <button className="voltar" onClick={voltarPaginaAnterior}>Voltar para a página anterior</button>}
            </LoadingTimeout >
            </div>
        </>
    )
}

export default Options1