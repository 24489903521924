import React from "react";
import Button from "../Button";
import LoadingTimeout from "./LoadingTimeout";

const Options13 = props => {
    const { stage, setStage, selectedOptions, setSelectedOptions, voltarPaginaAnterior } = props
    const headlineMap = {
        'centro_auditivo': 'Centenas de centros auditivos, assim como o seu, são parceiros Euro e aproveitam das diversas vantagens para aquisição de aparelhos',
        'insatisfeito_fornecedor': 'Já identificamos diversos casos, assim como o seu, de insatisfação com o atual fornecedor de aparelhos auditivos. Estamos aqui para mudar esta situação',
        'abrir_centro_auditivo': 'Já auxiliamos centenas de pessoas que querem abrir seu centro auditivo, assim como você, e contam com a parceria da Euro para adquirir produtos de qualidade com condições especiais',
        'sou_fonoaudiologo': 'Centenas de fonoaudiólogos(as) já adquiriram aparelhos auditivos pensando no bem estar de seus clientes, e agora pode ser a sua vez',
        'baixa_burocracia': 'do acesso facilitado à aparelhos auditivos sem burocracia para cadastro e compra de produtos',
        'envio': 'de envio rápido de produtos',
        'diversas_vantagens': 'das diversas vantegens de ser um parceiro Euro',
        'treinamento': 'de treinamento personalizado e suporte técnico eficiente'
    }
    return (
        <>
            <p className="headline3">{headlineMap[selectedOptions[1]]}.
            </p>
            <div className="answer-field">
                <LoadingTimeout timeout={5000}>
                    <Button nextStage={4} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions}>Entrar em contato com Euro Audiologia</Button>
                    {/* <Button nextStage={20} stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions}>Gostaria de mais informações</Button> */}
                    {/* {stage !== 0 && <button className="voltar" onClick={voltarPaginaAnterior}>Voltar para a página anterior</button>} */}
                </LoadingTimeout>
            </div>
        </>
    )
}

export default Options13