import React from "react";
import { useState } from "react";


const Options14 = (props) => {
    const { stage, selectedOptions, setSelectedOptions, voltarPaginaAnterior } = props
    const SERVER_ENDPOINT = 'https://unlimited-server.onrender.com/api/leads/add'
    // const SERVER_ENDPOINT = 'http://127.0.0.1:5000/api/leads/add'
    const [isLoading, setLoading] = useState(false)

    const messageMap = {
        centro_auditivo: "Possuo um centro auditivo",
        insatisfeito_fornecedor:
            "Estou insatisfeito com meu fornecedor",
        abrir_centro_auditivo:
            "Quero abrir meu centro auditivo",
        sou_fonoaudiologo: "Sou fonoaudiólogo(a)",
        'baixa_burocracia': 'Baixa burocracia para cadastro e compra de produtos',
        'treinamento': 'Treinamento e suporte técnico eficiente',
        'envio': 'Envio rápido de produtos',
        'diversas_vantagens': 'Procuro mais de uma vantagem citada'
    };
    const getFBP = () => {
        const fbpCookie = document.cookie
            .split('; ')
            .find(cookie => cookie.startsWith('_fbp='));
        return fbpCookie ? fbpCookie.split('=')[1] : null;
    };

    const getFBC = () => {
        const fbcCookie = document.cookie
            .split('; ')
            .find(row => row.startsWith('_fbc='));
        if (fbcCookie) {
            return fbcCookie.split('=')[1];
        }
        const urlParams = new URLSearchParams(window.location.search);
        const fbclid = urlParams.get('fbclid');
        if (fbclid) {
            return `fb.1.${Date.now()}.${fbclid}`;
        }
        return null;
    }

    const getGCLID = () => {
        if (typeof window === "undefined" || !window.location) {
            return null;
        }
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        const gclid = params.get('gclid');
        return gclid;
    }

    const openWhatsAppChat = () => {
        const phoneNumber = '555186443398'
        const message = `Olá Euro Audiologia!\n${messageMap[selectedOptions[1]]} e estou procurando por atacado de aparelhos auditivos.\nA principal vantagem que eu procuro na Euro é: ${messageMap[selectedOptions[2]]}.\nDados de contato:\nNome:${selectedOptions['name']}\nEmpresa:${selectedOptions['company']}\nEmail:${selectedOptions['email']}`
        const encodedMessage = encodeURIComponent(message);
        const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(url, '_blank');
    }

    const saveFormToDatabase = async (e) => {
        if (!isFormValid()) {
            window.alert('Informe seus dados no formulário')
            return
        }
        setLoading(true)
        let fbc = getFBC()
        let fbp = getFBP()
        let gclid = getGCLID()
        let source = gclid ? 'google' : fbc ? 'meta' : null
        let data = {
            source: source,
            name: selectedOptions['name'],
            phone: selectedOptions['phone'],
            domain_url: window.location.host,
            page_url: window.location.href,
            fbc: fbc,
            fbp: fbp,
            gclid: gclid,
            aditional_data: {
                company: selectedOptions['company'],
                finalidade: selectedOptions[1],
                vantagem: selectedOptions[2]
            }
        }

        try {
            const response = await fetch(SERVER_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }

            const responseData = await response.json();
            console.log('Success:', responseData);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            openWhatsAppChat();
            setLoading(false)
        }
    }



    // Function to format phone number
    const formatPhoneNumber = (input) => {
        let phoneNumber = input.replace(/\D/g, '');
        if (phoneNumber.length === 1) {
            // Format (x
            phoneNumber = "(" + phoneNumber.substring(0, 2);
        } else if (phoneNumber.length >= 2 && phoneNumber.length <= 6) {
            // Format (xx) xxxxx
            phoneNumber = "(" + phoneNumber.substring(0, 2) + ") " +
                phoneNumber.substring(2, 7)
        } else if (phoneNumber.length >= 7) {
            // Format (xx) xxxxx-9999
            phoneNumber = "(" + phoneNumber.substring(0, 2) + ") " +
                phoneNumber.substring(2, 7) + "-" +
                phoneNumber.substring(7, 11);
        }
        return phoneNumber;
    };

    const isFormValid = () => {
        let phonePattern = /^\(\d{2}\) \d{5}-\d{4}$/;
        let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        let nameOk = selectedOptions['name'].length > 0
        let companyOk = selectedOptions['company'].length > 0
        let emailOk = emailPattern.test(selectedOptions['email'])
        let phoneOk = phonePattern.test(selectedOptions['phone'])
        return nameOk && companyOk && emailOk && phoneOk
    }




    return (
        <>
            <p className="headline3">
                Por gentileza, informe seus dados e logo em seguida iremos conversar com você diretamente pelo Whatsapp.
            </p>
            <div className="answer-field">
                <input
                    placeholder="Seu nome"
                    onChange={(e) => {
                        let newOptions = { ...selectedOptions };
                        newOptions["name"] = e.target.value;
                        setSelectedOptions({ ...newOptions });
                    }}
                    value={selectedOptions["name"]}
                />
                <input
                    placeholder="Nome da sua empresa"
                    onChange={(e) => {
                        let newOptions = { ...selectedOptions };
                        newOptions["company"] = e.target.value;
                        setSelectedOptions({ ...newOptions });
                    }}
                    value={selectedOptions["company"]}
                />
                <input
                    placeholder="Seu email"
                    id="email"
                    onChange={(e) => {
                        let newOptions = { ...selectedOptions };
                        newOptions["email"] = e.target.value;
                        setSelectedOptions({ ...newOptions });
                    }}
                    value={selectedOptions["email"]}
                />

                <input
                    placeholder="Digite seu telefone com DDD"
                    id="phone"
                    onChange={(e) => {
                        let formattedPhoneNumber = formatPhoneNumber(e.target.value);
                        let newOptions = { ...selectedOptions };
                        newOptions["phone"] = formattedPhoneNumber;
                        setSelectedOptions({ ...newOptions });
                    }}
                    value={selectedOptions["phone"]}
                />


                {isLoading ? <span className="loader"></span> : <button onClick={saveFormToDatabase} style={{ marginTop: '20px' }} className={isFormValid() ? 'form-atacado-ok' : 'waiting-form'}>{isFormValid() ? 'Enviar' : 'Aguardando preenchimento...'}</button>}
                {/* {stage !== 0 && <button className="voltar" onClick={voltarPaginaAnterior}>Voltar para a página anterior</button>} */}
            </div>
        </>
    );
};

export default Options14;
