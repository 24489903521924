import React from "react";
import Options10 from "./Options10";
import Options11 from "./Options11";
import Options12 from "./Options12";
import Options13 from "./Options13";
import Options14 from "./Options14Form";
import Options21 from "./Options21";




const FormAtacado = props => {
    const { stage, setStage, selectedOptions, setSelectedOptions } = props

    const voltarPaginaAnterior = () => {
        if (stage === 20) setStage(3)
        // else if (stage == 1)  navigate("/")
        else setStage(stage - 1)
    }
    return (
        <div className="scroll">
            {stage === 0 && <Options10 stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} voltarPaginaAnterior={voltarPaginaAnterior}/>}
            {stage === 1 && <Options11 stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} voltarPaginaAnterior={voltarPaginaAnterior}/>}
            {stage === 2 && <Options12 stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} voltarPaginaAnterior={voltarPaginaAnterior}/>}
            {stage === 3 && <Options13 stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} voltarPaginaAnterior={voltarPaginaAnterior}/>}
            {stage === 4 && <Options14 stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} voltarPaginaAnterior={voltarPaginaAnterior}/>}
            {stage === 21 && <Options21 stage={stage} setStage={setStage} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} voltarPaginaAnterior={voltarPaginaAnterior}/>}
            </div>
    )
}

export default FormAtacado